import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as sharedComponents from './components';
import * as sharedDirectives from './directives';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './modules/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from 'src/app/core/core.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxChartsModule } from '@swimlane/ngx-charts';

const declarationsToExport = [...sharedComponents.components, ...sharedDirectives.directives];
const modulesToExport = [
  CoreModule,
  CommonModule,
  RouterModule,
  MaterialModule,
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  BrowserAnimationsModule,
  NgxDaterangepickerMd.forRoot(),
  BsDropdownModule.forRoot(),
  NgxChartsModule
];

@NgModule({
  declarations: [declarationsToExport],
  imports: [...modulesToExport],
  exports: [...modulesToExport, ...declarationsToExport]
})
export class SharedModule {}
