import { MembersComponent } from './features/private/members/members.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { NoAuthGuard } from './core/guards/no-auth.guard';
import { BookingsComponent } from './features/private/bookings/bookings.component';
import { CitiesComponent } from './features/private/cities/cities.component';
import { FlightSearchComponent } from './features/private/flight-searches/flight-searches.component';
import { DashboardComponent } from './features/private/dashboard/dashboard.component';
import { EnterDealCityComponent } from './features/private/enter-deal-city/enter-deal-city.component';
import { EnterDealsComponent } from './features/private/enter-deals/enter-deals.component';
import { IssueTicketComponent } from './features/private/issue-ticket/issue-ticket.component';
import { MissingRoutesComponent } from './features/private/missing-routes/missing-routes.component';
import { PrivateComponent } from './features/private/private.component';
import { RoutesComponent } from './features/private/routes/routes.component';
import { PublicComponent } from './features/public/public.component';
import { SignInComponent } from './features/public/sign-in/sign-in.component';
import { HomeAirportEmailComponent } from './features/private/home-airport-email/home-airport-email.component';
import { RecentMembersComponent } from './features/private/recent-members/recent-members.component';

const routes: Routes = [
  {
    path: '',
    component: PublicComponent,
    canActivate: [NoAuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'sign-in',
        pathMatch: 'full'
      },
      {
        path: 'sign-in',
        component: SignInComponent
      }
    ]
  },
  {
    path: '',
    component: PrivateComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'reset-deal-city',
        component: EnterDealCityComponent
      },
      {
        path: 'enter-deals',
        component: EnterDealsComponent
      },
      {
        path: 'routes',
        component: RoutesComponent
      },
      {
        path: 'missing/routes',
        component: MissingRoutesComponent
      },
      {
        path: 'cities',
        component: CitiesComponent
      },
      // {
      //   path: 'issue-ticket',
      //   component: IssueTicketComponent
      // },
      {
        path: 'bookings',
        component: BookingsComponent
      },
      {
        path: 'flight-searches',
        component: FlightSearchComponent
      },
      {
        path: 'members',
        component: MembersComponent
      },
      {
        path: 'recent-members',
        component: RecentMembersComponent
      },
      {
        path: 'home-airport',
        component: HomeAirportEmailComponent
      }
    ]
  },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
