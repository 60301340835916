import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DashboardService } from 'src/app/core/services/dashboard.service';
import { ConfirmModalComponent } from '../enter-deals/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { AlertComponent } from '../issue-ticket/issue-ticket.component';
import { ReusableConfirmationAlertComponent } from 'src/shared/components/reusable-confirmation-alert/reusable-confirmation-alert.component';

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {
  constructor(private dashboardService: DashboardService, private dialog: MatDialog) {}
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public displayedColumns: string[] = [
    'bookingId',
    'bookingNumber',
    'memberId',
    'emailId',
    'passengerCount',
    'travelType',
    'fareType',
    'bookingDate',
    'oneairTotalAmount',
    'bookingStatus',
    'paymentStatus',
    'supplier',
    'placeQueue',
    'cancelBooking',
    'checkVoidStatus'
  ];
  public searchControl: FormControl = new FormControl();
  public dataSource = new MatTableDataSource<any>([]);
  public loading: boolean = true;
  public bookingList: any[] = [];
  public showBookingDetails: boolean = false;
  public bookingDetails: any = null;
  public imageBaseUrl: string = environment.imageBaseUrl;

  ngOnInit(): void {
    this.getAllBookings();
  }

  public timeConverter(minutes: number): any {
    const seconds = minutes * 60;
    const h = Math.floor(minutes / 60);
    const m = Math.floor((seconds % 3600) / 60);
    const hDisplay = h > 0 ? h + 'h ' : '';
    const mDisplay = m > 0 ? m + 'm' : '';
    return (hDisplay + mDisplay).replace(/,\s*$/, '');
  }

  public totalTripTime(data: any): string {
    const totalDuration = data
      .map((_d: any) => {
        if (typeof _d.layoverDuration !== 'undefined') {
          return _d.duration + _d.layoverDuration;
        } else return _d.duration;
      })
      .reduce((a: any, b: any) => a + b, 0);
    return this.timeConverter(totalDuration);
  }

  public getAllBookings(): void {
    this.dashboardService.getAllBookings().subscribe((res: any) => {
      this.bookingList = res.data.tickets;
      this.dataSource = new MatTableDataSource<any>(this.bookingList);
      this.dataSource.paginator = this.paginator;
      this.loading = false;
    });
  }

  public searchItem(): void {
    const query = this.searchControl.value.toLowerCase();
    const result = this.bookingList.filter((data: any) => {
      return data.bookingNumber.toLowerCase().search(query) !== -1 || data.bookingId.toLowerCase().search(query) !== -1
      || data.memberId==query  || (data.member && data.member.emailId.toLowerCase().search(query) !== -1)
      || data.bookingDetails.some((detail:any) =>
        detail.passengerFirstname.toLowerCase().search(query)!==-1 ||
        detail.passengerLastname.toLowerCase().search(query)!==-1 
      );
    });
    this.dataSource = new MatTableDataSource<any>(result);
    this.dataSource.paginator = this.paginator;
  }

  public resetSearch(event: string): void {
    if (!event) {
      const result = this.bookingList.filter((data: any) => {
        return data.bookingNumber.toLowerCase().search('') !== -1 || data.bookingId.toLowerCase().search('') !== -1;
      });
      this.dataSource = new MatTableDataSource<any>(result);
      this.dataSource.paginator = this.paginator;
    }
  }

  public placeQueue(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      data: { body: `Are you sure you want to place booking number: ${data.bookingNumber} in ticketing queue?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          bookingId: data.bookingId,
          bookingNumber: data.bookingNumber
        };
        this.dashboardService.placeQueue(form).subscribe((res: any) => {
          this.getAllBookings();
        });
      }
    });
  }

  public cancelBooking(data: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: '400px',
      height: '200px',
      autoFocus: false,
      data: { body: `Are you sure you want to cancel booking number: ${data.bookingNumber}?` }
    });
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const form = {
          memberId: data.memberId,
          bookingId: data.bookingId
        };
        this.dashboardService.paymentVoid(form).subscribe(
          (res: any) => {
            this.getAllBookings();
          },
          (err: any) => {
            if (err.error.code === 400) {
              this.dialog.open(AlertComponent, {
                width: '25vw',
                height: '200px',
                autoFocus: false,
                data: { message: err.error.message }
              });
            }
          }
        );
      }
    });
  }

  public showDetails(data: any): void {
    this.dashboardService.viewBooking(data.memberId, data.bookingId).subscribe((res: any) => {
      this.showBookingDetails = true;
      const departure = res.data.flights.filter((d: any) => !d.segmentFlag);
      const arrival = res.data.flights.filter((d: any) => d.segmentFlag);
      this.bookingDetails = {
        ...res.data,
        arrival,
        departure
      };
    });
  }

  public backToList(): void {
    this.showBookingDetails = false;
  }

  public checkVoidStatus(data: any): void {
    const form = {
      memberId: data.memberId,
      bookingId: data.bookingId
    };
    this.dashboardService.checkTicketVoidStatus(form).subscribe(
      (res: any) => {
        this.dialog.open(ReusableConfirmationAlertComponent, {
          autoFocus: false,
          data: {
            message: res.message,
            yesMessage: 'OK',
            hasNoButton: false
          },
          panelClass: 'remove-destination-dialog'
        });
      },
      (err: any) => {
        this.dialog.open(ReusableConfirmationAlertComponent, {
          autoFocus: false,
          data: {
            message: err.error.message,
            yesMessage: 'OK',
            hasNoButton: false
          },
          panelClass: 'remove-destination-dialog'
        });
      }
    );
  }
}
